import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';
import {
    getDatabase,
    onValue,
    set,
    push,
    update,
    onDisconnect,
    ref,
    serverTimestamp,
    orderByChild,
    equalTo,
    query,
    limitToFirst,
    limitToLast,
    off,
    onChildChanged,
    onChildAdded,
    onChildRemoved,
    startAfter
} from 'firebase/database';
import { getStorage, ref as refStorage, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(config);

// autenticacion
export const auth = getAuth(app);
export const SignInWithEmailAndPassword = signInWithEmailAndPassword;
export const OnAuthStateChanged = onAuthStateChanged;
export const SignOut = signOut;
export const SendPasswordResetEmail = sendPasswordResetEmail;

// base de datos
export const Basedatos = getDatabase(app);
export const OnValue = onValue;
export const Setdb = set;
export const Updatedb = update;
export const Push = push;
export const OnDisconnect = onDisconnect;
export const Ref = ref;
export const Timestamp = serverTimestamp;
export const OrderByChild = orderByChild;
export const EqualTo = equalTo;
export const Query = query;
export const LimitToFirst = limitToFirst;
export const LimitToLast = limitToLast;
export const Off = off;
export const OnChildChanged = onChildChanged;
export const OnChildAdded = onChildAdded;
export const OnChildRemoved = onChildRemoved;
export const StartAfter = startAfter;

// storage
export const GetStorage = getStorage;
export const RefStorage = refStorage;
export const UploadBytesResumable = uploadBytesResumable;
export const GetDownloadURL = getDownloadURL;
export const DeleteObject = deleteObject;
export const Messaging = getMessaging(app);
export const OnBackgroundMessage = onBackgroundMessage;
