import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { db } from 'services/firebase';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

//context
import { ThemeProvider as CustomTheme } from './context/ThemeContext';

// loading data
import Root from 'Root';
import RootTheme from 'RootTheme';
import { useEffect } from 'react';
import { UseUserState } from 'context/UserContext';
// ==============================|| APP ||============================== //

const App = () => {
    const { datos, user } = UseUserState();
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        window.addEventListener('beforeunload', function (e) {
            closeWindow();
        });
    }, []);

    const closeWindow = () => {
        DesasignarVentanilla();
    };

    const DesasignarVentanilla = async () => {
        if (!datos.ventanilla) return;
        db.set(`ventanilla/${datos.id_empresa}/${datos.sede}/${datos.ventanilla}/usuario_id`, null);
        db.set(`usuarios/${user.id}/ventanilla`, null);
    };

    useEffect(() => {}, [datos, user]);

    return (
        <Root>
            <CustomTheme>
                <RootTheme>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={themes(customization)}>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </RootTheme>
            </CustomTheme>
        </Root>
    );
};

export default App;
