import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { db } from 'services/firebase';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { SetVentanilla, UseUserState, UseUserDispatch } from 'context/UserContext';
import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [open, setOpen] = useState(false);
    const [ventanillas, setVentanillas] = useState([]);
    const [ventanillaSeleccionada, setVentanillaSeleccionada] = useState(0);
    const { datos, user } = UseUserState();

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const dispatchUser = UseUserDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const desasignarVentanilla = () => {
        setOpen(false);
        db.set(`ventanilla/${datos.id_empresa}/${datos.sede}/${datos.ventanilla}/usuario_id`, null);
        db.set(`usuarios/${user.id}/ventanilla`, null);
        SetVentanilla(dispatchUser, 0);
    };

    const handleConfirm = () => {
        setOpen(false);
        db.set(`usuarios/${user.id}/ventanilla`, parseInt(ventanillaSeleccionada));
        db.update(`ventanilla/${datos.id_empresa}/${datos.sede}/${ventanillaSeleccionada}`, {
            usuario_id: user.id
        });
        SetVentanilla(dispatchUser, ventanillaSeleccionada);
    };

    const handleVentanillaChange = (event) => {
        setVentanillaSeleccionada(event.target.value);
    };

    useEffect(() => {
        loadVentanillas();
        if (!datos.ventanilla) {
            handleClickOpen();
        }
    }, []);

    const loadVentanillas = () => {
        db.read.oncequery(db.query(db.ref(`ventanilla/${datos.id_empresa}/${datos.sede}`)), (snap) => {
            snap.forEach((item, i) => {
                let data = item.val();
                if (!('usuario_id' in data) && data.estado !== 'cerrado') {
                    data = { ...data, id: item.key };
                    setVentanillas((prevState) => [...prevState, data]);
                    if (ventanillaSeleccionada === 0) {
                        setVentanillaSeleccionada(data.id);
                    }
                }
            });
        });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                    backgroundColor: '#071e6a'
                }}
            >
                <Toolbar>
                    <Header handleClickOpen={handleClickOpen} handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar handleClickOpen={handleClickOpen} drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            {/* <Customization /> */}
            <Dialog fullWidth={true} open={open} onClose={handleClose}>
                <DialogTitle>Ventanilla</DialogTitle>
                <DialogContent>
                    <DialogContentText> Por favor seleccione la ventanilla que desea usar en esta sesión.</DialogContentText>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content'
                        }}
                    >
                        <FormControl sx={{ mt: 2, minWidth: 120 }}>
                            <InputLabel id="Ventanillas" htmlFor="Ventanillas">
                                Ventanillas
                            </InputLabel>
                            <Select
                                value={ventanillaSeleccionada}
                                onChange={handleVentanillaChange}
                                label="Ventanillas"
                                labelId="Ventanillas"
                                id="Ventanillas"
                            >
                                {ventanillas.map((item, i) => {
                                    return (
                                        <MenuItem key={i} value={item.id}>
                                            {item.id}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {datos.ventanilla !== undefined && datos.ventanilla !== null && datos.ventanilla !== 0 && (
                        <Button onClick={desasignarVentanilla}>Desasignar ventanilla actual</Button>
                    )}
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button onClick={handleConfirm}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MainLayout;
